<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: "LOAD MORE",
  },
});
</script>

<template>
  <button type="button">
    {{ props.title }}
  </button>
</template>

<style lang="scss" scoped>
button {
  min-width: 127px;
  padding: 14px 25px;
  border: 1px solid #000;
  background-color: #fff;
  font-size: 13px;
  text-align: center;
  line-height: 1.5;
  transition: all 0.5s ease-in-out;

  @include max-media(480) {
    font-size: 7px;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}
</style>
